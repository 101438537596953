import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

//router
import { createBrowserRouter, RouterProvider } from "react-router-dom";
//store
import { Provider } from "react-redux";
//reducer
import { store } from "./store";

// import Index from "./views/index";
import Default from './layouts/dashboard/default';
import { IndexRouters } from "./router";
import { SimpleRouter } from "./router/simple-router";
import { DefaultRouter } from "./router/default-router";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Default />,
  },
  ...DefaultRouter,
  ...IndexRouters,
  ...SimpleRouter
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <App>
        <RouterProvider router={router}></RouterProvider>
      </App>
    </Provider>
  </React.StrictMode>
);
