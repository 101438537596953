import React, { useState, useEffect, Fragment } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import TextField from '@mui/material/TextField';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DateRange } from 'react-date-range';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axiosInstance from '../../../js/api';
import { ToastContainer, toast } from 'react-toastify'


function Attendance() {
    const [attendanceData, setAttendanceData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('All data');
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection',
        },
    ]);
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const handleFilterChange = (event) => {
        setSelectedFilter(event.target.value);
    };

    const handleDateRangeButtonClick = () => {
        setShowDateRangePicker(!showDateRangePicker);
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage - 1);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axiosInstance.get('/user/get');
                setUserData(response.data.data);
            } catch (error) {
                console.error('Error fetching attendance data:', error);
                toast.error('Error fetching attendance data');
            }
        };

        const fetchAttendanceData = async () => {
            try {
                const response = await axiosInstance.get('/attendance/get-attendance');
                setAttendanceData(response.data.data);
            } catch (error) {
                console.error('Error fetching attendance data:', error);
                toast.error('Error fetching attendance data');
            }
        };
        fetchUserData()
        fetchAttendanceData();
    }, []);

    const offset = currentPage * itemsPerPage;

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(0);
    };

    const filterData = (data) => {
        return data.filter(
            (item) =>
                item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.last_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    const calculateAttendance = (userId) => {
        const userAttendances = attendanceData.filter((attendance) => attendance.personID === userId);
        const totalDays = userAttendances.length;
        const presentDays = userAttendances.filter((attendance) => attendance.attendanceType === 'Present').length;
        const absentDays = totalDays - presentDays;

        return { totalDays, presentDays, absentDays };
    };

    const handleViewAttendance = (userId) => {
        const { totalDays, presentDays, absentDays } = calculateAttendance(userId);
        alert(`Total Days: ${totalDays}\nPresent Days: ${presentDays}\nAbsent Days: ${absentDays}`);
    };

    const handleAddManualAttendance = async (userId) => {
        const currentDate = new Date();
        const manualAttendanceData = {
            personID: userId,
            attendanceType: 'Manual',
            CheckInTime: '10:00AM',
            CheckOutTime: '18:00PM',
            attendanceID: Math.floor(Math.random() * 100000000),
            attendanceDate: `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`,
        };

        try {
            await axiosInstance.post('/attendance/add-attendance', manualAttendanceData);
            toast.success('Manual attendance added successfully!');

            const response = await axiosInstance.get('/attendance/get-attendance');
            setAttendanceData(response.data.data);
        } catch (error) {
            console.error('Error adding manual attendance:', error);
            toast.error('Error adding manual attendance');
        }
    };

    return (
        <>
            <div className='margintop'>
                <Fragment>
                    <Row>
                        <Col sm='12'>
                            <Card>
                                <Card.Header className='d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h3 className='card-title'>Attendance</h3>
                                    </div>
                                </Card.Header>
                                <Card.Body className='px-0' style={{ position: 'relative' }}>
                                    <div className='card-body pt-0'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='header-title'>
                                                <TextField
                                                    placeholder='Search'
                                                    id='outlined-size-small'
                                                    size='small'
                                                    className='search-filed'
                                                    style={{ marginRight: '10px', width: '300px' }}
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                />
                                                <Button
                                                    className='btn btn-btn btn-primary ml-60 ml-md-0'
                                                    onClick={handleDateRangeButtonClick}
                                                    style={{ padding: '5px' }}
                                                >
                                                    <DateRangeIcon style={{ color: '#fff' }} />
                                                </Button>
                                                {showDateRangePicker && (
                                                    <div
                                                        className='date-filter'
                                                        style={{
                                                            position: 'absolute',
                                                            left: '28%',
                                                            zIndex: '999',
                                                            boxShadow:
                                                                'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                                                        }}
                                                    >
                                                        <DateRange
                                                            editableDateInputs={true}
                                                            onChange={(item) => setState([item.selection])}
                                                            moveRangeOnFirstSelection={false}
                                                            ranges={state}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className='header-title'>
                                                <label>Show rows:</label>
                                                <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={15}>15</option>
                                                    <option value={20}>20</option>
                                                    <option value={25}>25</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='table-responsive'>
                                        <table
                                            id='order-list-table'
                                            className='table table-striped'
                                            role='grid'
                                            data-toggle='data-table'
                                        >
                                            <thead>
                                                <tr className='ligth'>
                                                    <th>No.</th>
                                                    <th>Client Name</th>
                                                    <th>Contact no.</th>
                                                    <th>Total Absent</th>
                                                    <th>Total Present</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterData(userData)
                                                    .slice(offset, offset + itemsPerPage)
                                                    .map((user, index) => {
                                                        const { totalDays, presentDays, absentDays } = calculateAttendance(user._id);

                                                        return (
                                                            <tr key={index}>
                                                                <td>{offset + index + 1}</td>
                                                                <td>{user.first_name + ' ' + user.last_name}</td>
                                                                <td>{user.contactNumber}</td>
                                                                <td>{absentDays}</td>
                                                                <td>{presentDays}</td>
                                                                <td>
                                                                    <div className='flex align-items-center list-user-action'>
                                                                        <Button
                                                                            className='btn btn-sm btn-icon btn-success'
                                                                            onClick={() => handleViewAttendance(user._id)}
                                                                        >
                                                                            <span className='btn-inner'>
                                                                                <DoneIcon style={{ width: '32px', marginTop: '7px' }} />
                                                                            </span>
                                                                        </Button>
                                                                        <Button
                                                                            style={{ marginLeft: '10px' }}
                                                                            className='btn btn-sm btn-icon btn-danger'
                                                                            onClick={() => handleAddManualAttendance(user._id)}
                                                                        >
                                                                            <CloseIcon />
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='pagination-container'>
                                        <Stack spacing={2} >
                                            <Pagination
                                                count={Math.ceil(filterData(userData).length / itemsPerPage)}
                                                page={currentPage + 1}
                                                onChange={handlePageChange}
                                                variant='outlined'
                                                shape='rounded'
                                            />
                                        </Stack>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Fragment>
            </div>
            <ToastContainer />
        </>
    );
};

export default Attendance;
